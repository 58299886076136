import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { MdDehaze, MdClear } from 'react-icons/md'
import { PoseGroup } from 'react-pose'
import { Container, Button, Drawer, Overlay } from './mobilenavigation.style'
import { MobileMenu } from 'components'
import colors from '../../utils/colors'

class MobileNavigation extends Component {
  static propTypes = {
    route: PropTypes.string,
  }

  static defaultProps = {
    route: 'Home',
  }

  state = {
    open: false,
  }

  toggleDrawer = () => {
    this.setState({ open: !this.state.open })
  }

  closeDrawer = () => {
    this.setState({ open: false })
  }

  onDrawerClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { open } = this.state

    return (
      <Container>
        <Button onClick={this.toggleDrawer}>
          <MdDehaze style={{ color: colors.lightTeal, width: 30, height: 30 }} />
        </Button>
        <PoseGroup>
          {open && <Overlay key="overlay" onClick={this.toggleDrawer} />}
          <Drawer key="drawer" pose={open ? 'open' : 'closed'} onClick={this.toggleDrawer}>
            <Button onClick={this.toggleDrawer}>
              <MdClear
                style={{
                  display: open ? 'block' : 'none',
                  width: 30,
                  height: 30,
                  color: colors.pureWhite,
                  position: 'absolute',
                  top: 10,
                  right: 10,
                }}
              />
            </Button>
            <div style={{ marginTop: 40 }}>
              <MobileMenu />
            </div>
          </Drawer>
        </PoseGroup>
      </Container>
    )
  }
}

export default MobileNavigation
