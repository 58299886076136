const colors = {
  background: '#fff',
  black: '#000',
  black30: 'rgba(0, 0, 0, 0.3)',
  darkTeal: '#226279',
  footerBackground: '#262626',
  headerTeal: '#386f83',
  lightBlack: 'rgba(21, 21, 21, 0.8)',
  lightGray: '#e1e1e1',
  lightTeal: '#45afab',
  mediumBlack: '#151515',
  mediumGray: '#f5f5f5',
  primary: '#33e',
  red: '#ff0000',
  subtitleTeal: '#6491a1',
  text: '#000',
  white: '#fff',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
}

const fonts = {
  main: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif`,
  body: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif`,
  heading: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif`,
  monospace: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif`,
}

const theme = {
  breakpoints: ['751px', '1280px'],
  fonts,
  colors,
}

theme.breakpoints.md = theme.breakpoints[0]
theme.breakpoints.lg = theme.breakpoints[1]
theme.breakpoints.mm = '1080px'

export { theme, fonts, colors }
