import React, { Component } from 'react'
import system from '@rebass/components'
import { Box } from 'rebass'
import { colors } from 'theme'

const IndicatorContainer = system({
  extend: Box,
  ml: '24px',
})

const Indicator = system(
  {
    extend: Box,
    mb: '17px',
    width: '14px',
    height: '14px',
  },
  'space',
  'width',
  'height',
  p => ({
    backgroundColor: p.active ? colors.darkTeal : '#ebebeb',
    cursor: 'pointer',
  }),
)

class Indicators extends Component {
  state = {
    activeIndex: 0,
  }

  setActiveIndex = activeIndex => {
    this.setState({ activeIndex })
  }

  render() {
    const { slideshow, onIndicatorClicked } = this.props
    const { activeIndex } = this.state

    return (
      <IndicatorContainer>
        {slideshow.map((_, idx) => (
          <Indicator
            onClick={() => onIndicatorClicked(idx)}
            active={idx === activeIndex}
            key={idx}
          />
        ))}
      </IndicatorContainer>
    )
  }
}

export { Indicators }
