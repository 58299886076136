import React, { Component } from 'react'
import Link from 'gatsby-link'
import system from '@rebass/components'
import { Box, Text, Link as ExternalLink } from 'rebass'
import { Container, Menu, MenuItem, SubMenu, SubMenuItem } from './navigation.style'
import { colors } from 'theme'
import { ContactSupport } from 'styled-icons/material/ContactSupport'
const ContactsContainer = system(
  {
    extend: Box,
    display: { md: 'none', mm: 'block' },
  },
  'display',
  {
    lineHeight: '24px',
  },
)

const Phone = system(
  {
    extend: Text,
    color: colors.darkTeal,
  },
  {
    a: {
      color: colors.darkTeal,
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const Email = system(
  {
    extend: Text,
  },
  {
    a: {
      fontSize: '14px',
      color: colors.darkTeal,
      fontWeight: '400',
      textTransform: 'none',
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

class Navigation extends Component {
  render() {
    return (
      <Container>
        <Menu>
          <MenuItem>
            <Link to="/">home</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/services">services</Link>
            <SubMenu>
              <SubMenuItem>
                <Link to="/services/construction">Construction</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/services/fabrication">Fabrication</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/services/turnarounds">Turnarounds</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/services/maintenance">Maintenance</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/services/repairs">Repairs</Link>
              </SubMenuItem>
            </SubMenu>
          </MenuItem>
          <MenuItem>
            <Link to="/about">about us</Link>
            <SubMenu>
              <SubMenuItem>
                <Link to="/about/leadership">Leadership</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/about/our-history">Our History</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/about/culture-and-values">Culture & Values</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/about/community-involvement">Community Involvement</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/about/geographic-reach">Geographic Reach</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/about/industries-served">Industries Served</Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link to="/news">News</Link>
              </SubMenuItem>
            </SubMenu>
          </MenuItem>
          <MenuItem>
            <Link to="/careers">careers</Link>
          </MenuItem>
          <MenuItem mmHide>
            <Link to="/#contact-form">
              <ContactSupport size={20} />
            </Link>
          </MenuItem>
          <MenuItem mmHide>
            <ContactsContainer>
              <Phone><ExternalLink href="tel:8444228262">(844) 4-CATAMARAN</ExternalLink></Phone>
              <Email>
                <ExternalLink href="mailto:info@catamaransolutions.com">
                  info@catamaransolutions.com
                </ExternalLink>
              </Email>
            </ContactsContainer>
          </MenuItem>
        </Menu>
      </Container>
    )
  }
}

export default Navigation
