import React from 'react'
import system from '@rebass/components'
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Text } from 'rebass'
import { theme, colors, fonts } from 'theme'

const Button = system(
  {
    extend: Flex,
    alignItems: 'center',
    bg: colors.darkTeal,
    borderBottom: ['3px solid #fff', '6px solid #fff'],
    justifyContent: 'center',
  },
  'borderBottom',
  'height',
  'space',
  'width',
  {
    '&:hover': {
      backgroundColor: theme.colors.lightTeal,
      textDecoration: 'none',
    },
  },
)

const CTAText = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: ['12px', '14px'],
    fontWeight: ['700', '900'],
    letterSpacing: '0.2em',
    p: ['10px', '30px'],
  },
  'space',
  () => ({
    textTransform: 'uppercase',
    textDecoration: 'none !important',
  }),
)

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} partiallyActive={partiallyActive}>
        <Button {...other}>
          <CTAText>{children}</CTAText>
        </Button>
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...other}>
      <Button {...other}>
        <CTAText>{children}</CTAText>
      </Button>
    </a>
  )
}

export const LinkButton = system(
  {
    extend: Link,
  },
  {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
)
