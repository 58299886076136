import posed from 'react-pose'
import styled from 'styled-components'
import { colors } from 'theme'

const Container = styled.div`
  display: block;

  @media (min-width: ${p => p.theme.breakpoints[0]}) {
    display: none;
  }
`

const Button = styled.button`
  background: none;
  color: ${colors.background};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const PosedOverlay = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: { duration: 200 },
  },
})

const Overlay = styled(PosedOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  /* animate.css */
  -webkit-animation-duration: 0.4s; /* Chrome, Safari, Opera */
  animation-duration: 0.2s;
`

const PosedDrawer = posed.div({
  closed: { width: 0 },
  open: { width: 300 },
})

const Drawer = styled(PosedDrawer)`
  height: 100%;
  top: 0;
  right: 0;
  width: 0;
  background-color: ${colors.darkTeal};
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

export { Container, Overlay, Button, Drawer }
