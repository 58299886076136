import styled from 'styled-components'
import system from '@rebass/components'
import { Flex } from 'rebass'
import { colors, fonts } from 'theme'

const white = colors.background
const darkTeal = colors.darkTeal

const Container = system(
  {
    extend: Flex,
    as: 'nav',
    alignItems: 'flex-end',
    color: white,
    display: ['none', 'flex'],
    flexDirection: 'column',
    fontSize: '20px',
  },
  'color',
  'display',
  'fontSize',
  {
    cursor: 'pointer',
  },
)

const Menu = styled.ul`
  display: flex;
  align-items: center;
  cursor: pointer;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  line-height: ${p => (p.small ? '18px' : '45px')};
  font-weight: 600;
  text-transform: uppercase;
`

const MenuItem = styled.li`
  cursor: pointer;
  margin-bottom: 0px;
  &:hover {
    ul {
      display: block;
    }
  }

  display: inline-block;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  font-family: ${fonts.main};
  letter-spacing: 0.075em;
  font-weight: 500;

  a {
    display: block;
    line-height: inherit;
    text-decoration: none;
    color: ${colors.darkTeal};
    font-weight: 500;
  }

  a:hover {
    color: ${colors.lightTeal};
  }

  @media (max-width: ${p => p.theme.breakpoints.mm}) {
    ${p => p.mmHide ? 'display: none;' : null};
  }
`

const SubMenu = styled.ul`
  cursor: pointer;
  &:hover {
    display: block;
  }
  display: none;
  font-weight: 400;
  text-transform: none;
  position: absolute;
  list-style: none;
  width: 300px;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0px;
  background-color: ${white};
  border: 1px solid ${darkTeal};
  z-index: 999;
`

const SubMenuItem = styled.li`
  cursor: pointer;
  a {
    display: block;
    line-height: inherit;
    text-decoration: none;
    color: ${darkTeal};
    font-weight: 400;
  }
`

export { Container, Menu, MenuItem, SubMenu, SubMenuItem }
