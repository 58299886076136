import styled from 'styled-components'
import { colors, fonts } from 'theme'

const hoveredBoxShadow = `0 2px 4px 0 rgb(0, 0, 0, 0.06), 0 4px 8px 0 rgb(0, 0, 0, 0.06)`

export const Container = styled.div`
  position: relative;
  margin: 0;
  height: ${p => p.type === 'textarea' ? '112px' : '56px'};
  padding: 10px 0px 0px 0;
  cursor: text;
  box-sizing: border-box;
  transition: 0.2s ease all;
  text-align: left;

  bow-shadow: ${p =>
    p.focused ? 'none' : !p.hovered ? 'none' : hoveredBoxShadow};

  background-color: ${p =>
    p.variant === 'dark' ? '#777' : colors.white};
  border-bottom: ${p =>
    p.variant === 'dark' ? `1px solid #777` : 'none'};

  textarea {
    resize: none;
  }

  input, textarea {
    font-size: 16px;
    font-family: ${fonts.main};
    color: ${colors.mediumBlack};
    margin-top: 8px;
    padding: 10px 12px 10px 12px;
    display: block;
    width: 100%;
    border: none;
    background-color: transparent;
  }

  input:focus, textarea:focus {
    outline: none;
  }

  input:required, textarea:required {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.06), 0 4px 8px 0 rgb(0, 0, 0, 0.06);
  }

  &:hover input:focus {
    box-shadow: none;
  }
  
  &:hover textarea:focus {
    box-shadow: none;
  }

  label {
    font-size: 16px;
    font-weight: normal;
    font-family: ${fonts.main};
    letter-spacing: 0.025em;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 20px;
    transition: 0.2s ease all;
  }

  input.used ~ label,
  input:focus ~ label,
  input:valid ~ label {
    top: 10px;
    font-size: 13px;
  }

  textarea.used ~ label,
  textarea:focus ~ label,
  textarea:valid ~ label {
    top: 10px;
    font-size: 13px;
  }

  .bar {
    position: absolute;
    display: block;
    width: 0;
    left: 50%;
    height: 2px;
    bottom: 0px;
    transition: 0.2s ease all;
  }

  input:focus ~ .bar,
  .bar.open {
    left: 0;
    width: 100%;
  }

  textarea:focus ~ .bar,
  .bar.open {
    left: 0;
    width: 100%;
  }

  .info {
    font-size: 0.75rem;
    margin: 4px 0 0px 12px;
  }
`
