import React from 'react'
import Link from 'gatsby-link'
import { Container, Image } from './logo.style'
import logo from '../../images/logo_wide_regular.svg'

export default () => (
  <Container>
    <Link to="/">
      <Image src={logo} alt="Catamaran Logo" />
    </Link>
  </Container>
)
