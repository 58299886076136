import { pick } from 'lodash'
import React, { Component } from 'react'
import classNames from 'classnames'
import Ripple from 'react-material-ripple'
import { Visibility } from 'styled-icons/material/Visibility'
import { VisibilityOff } from 'styled-icons/material/VisibilityOff'
import NumberFormat from 'react-number-format'
import { colors } from 'theme'
import { Container } from './container'

class InputWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      color: props.labelColor,
      adornmentColor: props.labelColor,
      showPassword: false,
      showError: false,
      focused: false,
      hovered: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ showError: true })
    }
  }

  static defaultProps = {
    focusedLabelColor: colors.lightTeal,
    labelColor: '#777',
  }

  onFocusWrapper = e => {
    const { focusedLabelColor, onFocus } = this.props
    this.setState({
      color: focusedLabelColor,
      adornmentColor: colors.pureBlack,
      focused: true,
    })
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }

  onBlurWrapper = () => {
    const { labelColor, onBlur } = this.props
    this.setState({
      color: labelColor,
      adornmentColor: labelColor,
      focused: false,
      showError: true,
    })
    if (typeof onBlur === 'function') {
      onBlur()
    }
  }

  onChange = e => {
    const { onChange } = this.props
    this.setState({ showError: false })
    onChange && onChange(e)
  }

  renderInput = () => {
    const { variant, type, inputStyle, value, tabIndex } = this.props

    const { showPassword } = this.state
    const used = value && String(value).length

    const defaultProps = {
      className: classNames({ used }),
      'data-test': this.props.dataTest,
      onFocus: this.onFocusWrapper,
      onBlur: this.onBlurWrapper,
      style: inputStyle,
      onMouseOver: () => this.setState({ hovered: true }),
      onMouseOut: () => this.setState({ hovered: false }),
      ...pick(this.props, ['label', 'value', 'readOnly', 'error', 'disabled']),
    }

    switch (type) {
      case 'money':
        return (
          <NumberFormat
            {...defaultProps}
            prefix="$"
            thousandSeparator={true}
            allowNegative={false}
            placeholder=""
            onValueChange={e => this.onChange(e.value)}
          />
        )
      case 'password':
        return (
          <input
            {...defaultProps}
            onChange={this.onChange}
            type={showPassword ? 'text' : 'password'}
            style={{
              paddingRight: 48,
              backgroundColor: variant === 'dark' ? '#777' : colors.pureWhite,
            }}
            required
            tabIndex={tabIndex}
          />
        )
      case 'textarea':
        return (
          <textarea
            rows="4"
            onChange={this.onChange}
            {...defaultProps}
            required
            tabIndex={tabIndex}
          />
        )
      default:
        return <input onChange={this.onChange} {...defaultProps} required tabIndex={tabIndex} />
    }
  }

  renderRightIcon = () => {
    const { type } = this.props
    const { adornmentColor, showPassword } = this.state

    if (type === 'password') {
      return (
        <div
          style={{ position: 'absolute', right: '12px', top: '16px', cursor: 'pointer' }}
          onClick={() => {
            this.setState({ showPassword: !showPassword })
          }}>
          <Ripple style={{ borderRadius: '10px', overflow: 'visible' }}>
            {!showPassword ? (
              <VisibilityOff size={24} color={adornmentColor} />
            ) : (
              <Visibility size={24} color={adornmentColor} />
            )}
          </Ripple>
        </div>
      )
    }
    return null
  }

  hasError = () => {
    const { error, value } = this.props
    const { showError } = this.state

    // const used = !!value
    return error && showError
  }

  renderError = () => {
    if (this.hasError()) {
      return (
        <div
          className="info"
          style={{
            color: colors.red,
          }}>
          {this.props.error}
        </div>
      )
    }
    return null
  }

  renderBottomBar = () => {
    return (
      <span
        className="bar"
        style={{
          backgroundColor: this.hasError() ? colors.red : this.props.focusedLabelColor,
        }}
      />
    )
  }

  renderLabel = () => {
    return (
      <label>
        <span style={{ color: this.hasError() ? colors.red : this.state.color }}>
          {this.props.label}
        </span>
      </label>
    )
  }

  renderHelperText = () => {
    const { helperText } = this.props

    if (!this.hasError() && helperText) {
      return (
        <div
          className="info"
          style={{
            color: colors.mediumBlack,
          }}>
          {helperText}
        </div>
      )
    }
    return null
  }

  render() {
    const { variant, style, type } = this.props
    const { focused, hovered } = this.state

    return (
      // @ts-ignore
      <Container type={type} variant={variant} style={style} focused={focused} hovered={hovered}>
        {this.renderInput()}
        {this.renderBottomBar()}
        {this.renderLabel()}
        {this.renderRightIcon()}
        {this.renderError()}
        {this.renderHelperText()}
      </Container>
    )
  }
}

const Input = props => {
  return <InputWrapper {...props} />
}

export { Input }
