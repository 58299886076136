import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'theme'

const styles = () => ({
  colorPrimary: {
    color: colors.darkTeal,
  },
  colorSecondary: {
    color: colors.white,
  },
})

const Progress = withStyles(styles)(p => <CircularProgress {...p} />)

const Loading = props => {
  const { color = "primary" } = props

    return <Progress color={color} size={24} thickness={2} />
}

export { Loading }
