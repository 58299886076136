import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { PickerOverlay } from 'filestack-react'

// export type FileType =
//   | '.pdf'
//   | 'image/jpeg'
//   | 'image/*'
//   | 'video/*'
//   | 'audio/*'
//   | 'application/*'
//   | 'text/*'

export const FileUpload = ({
  onSuccess,
  onError = (_) => {},
  onClose = () => {},
  onCancel = () => {},
  maxFiles = 1,
}) => {
  const [error, setError] = useState(null)

  return (
    <>
      {createPortal(
        <PickerOverlay
          apikey={process.env.FILESTACK_API_KEY}
          onSuccess={onSuccess}
          onError={(e) => {
            console.error(e)
            setError(e)
            onError(e)
          }}
          pickerOptions={{
            onClose,
            onCancel,
            maxFiles,
            fromSources: ['local_file_system'],
          }}
        />,
        document.body,
      )}
    </>
  )
}

export default FileUpload
