import styled from 'styled-components'
import Header from '../Header'
import { Footer } from 'components'
import colors from '../../utils/colors'

const Page = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background-color: ${colors.backgroundWhite};
`


const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0;
`

const Wrapper = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  flex: 1 0 0;
  width: 100%;
  align-self: center;
  color: ${colors.lightBlack};
  h1 {
    color: ${colors.lightBlack};
  }
  a {
    color: ${colors.lightTeal};
  }
`

export { Header, Footer, Content, Container, Wrapper, Page }
