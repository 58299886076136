import React from 'react'
import system from '@rebass/components'
import { Flex } from 'rebass'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
    pt: '90px',
    pb: '45px',
  },
  'color',
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flex: 1,
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    px: '20px',
    width: '100%',
  },
  'width',
  'flex',
  'maxWidth',
)

export const ContentContainer = ({
  children,
  pb = '45px',
  pt = '90px',
  justifyContent = 'center',
  ...rest
}) => (
  <Container pb={pb} pt={pt} {...rest}>
    <FullContainer justifyContent={justifyContent}>{children}</FullContainer>
  </Container>
)
