import styled from 'styled-components'

const Container = styled.div`
  margin: 0;
  padding: 0;
`

const Image = styled.img`
  width: 200px;
  margin: 0px;
`

export { Container, Image }