import React from 'react'
import system from '@rebass/components'
import PrismicDOM from 'prismic-dom'
import { get } from 'lodash'
import { Box, Flex, Text } from 'rebass'
import { colors, fonts } from 'theme'
import { Link } from 'components'
import { linkResolver } from 'utils'

const Container = system(
  {
    extend: Flex,
    justifyContent: 'center',
    pt: ['45px', '45px', '90px'],
    pb: '45px',
  },
  'space',
)

const FullContainer = system(
  {
    extend: Flex,
    flexDirection: ['column', 'row'],
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    maxWidth: ['100%', '740px', '1100px'],
    px: '20px',
  },
  'width',
  'flex',
  'maxWidth',
)

const Content = system(
  {
    extend: Box,
    flex: 0.7,
    pr: '15px',
  },
  'space',
)

const Menu = system(
  {
    extend: Box,
    flex: 0.3,
    display: ['none', 'block'],
    mx: '15px',
    pl: '64px',
    borderLeft: `1px solid #e1e1e1`,
  },
  'display',
  'space',
  'borderLeft',
)

const MenuTitle = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: ['18px', '18px', '24px'],
    lineHeight: '1.25',
    fontWeight: '700',
    mb: '20px',
  },
  'space',
  {
    letterSpacing: '.2em',
    textTransform: 'uppercase',
  },
)

const MenuItem = system(
  {
    extend: Text,
    py: '20px',
    color: 'rgb(21, 21, 21)',
    fontSize: '14px',
    fontWeight: '400',
    position: 'relative',
  },
  'space',
  'borderBottom',
  'position',
  {
    textTransform: 'uppercase',
    textDecoration: 'none',
    letterSpacing: '.1em',
    transition: 'all .3s ease',
    '&::before': {
      position: 'absolute',
      content: "''",
      bottom: '-1px',
      left: 0,
      height: '1px',
      width: '100%',
      background: '#e1e1e1',
      transition: 'all .3s ease',
    },
    '&:hover': {
      '&::before': {
        position: 'absolute',
        content: "''",
        bottom: '-1px',
        left: 0,
        height: '3px',
        width: '100%',
        background: colors.lightTeal,
        transition: 'all .1s ease',
      },
    },
  },
)

export const ContentWithSideMenu = ({ children, menu, uid }) => {
  return (
    <Container>
      <FullContainer>
        <Content>{children}</Content>
        <Menu>
          {menu && (
            <>
              <MenuTitle>{PrismicDOM.RichText.asText(menu.title)}</MenuTitle>
              <>
                {menu.items.map((item, idx) => {
                  if (item.show_in_side_menu && 'Yes' && uid !== get(item, 'link._meta.uid')) {
                    return (
                      <Link
                        key={idx}
                        style={{ textDecoration: 'none' }}
                        to={linkResolver(item.link)}>
                        <MenuItem>{item.name}</MenuItem>
                      </Link>
                    )
                  } else {
                    return null
                  }
                })}
              </>
            </>
          )}
        </Menu>
      </FullContainer>
    </Container>
  )
}
