export const linkResolver = link => {
  if (typeof link === 'object' && link['_linkType']) {
    switch (link['_linkType']) {
      case 'Link.document':
        switch (link['__typename']) {
          case 'PRISMIC_Service':
            return `/services/${link['_meta'].uid}`
          case 'PRISMIC_About':
            return `/about/${link['_meta'].uid}`
          case 'PRISMIC_News':
            return `/news/${link['_meta'].uid}`
          case 'PRISMIC_Menu':
            return `/${link['_meta'].uid}`
          default:
            return '/'
        }
      default:
        return null
    }
  } else {
    return link
  }
}