import React, { Component } from 'react'
import system from '@rebass/components'
import { Flex, Box as BoxBase } from 'rebass'
import Logo from 'components/Logo'
import Navigation from 'components/Navigation'
import MobileNavigation from 'components/MobileNavigation'
import { colors } from 'theme'

const Box = system(
  {
    extend: BoxBase,
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },
  'position',
  'top',
  'zIndex',
)

const Wrapper = system(
  {
    extend: Flex,
    as: 'header',
    display: 'flex',
    bg: colors.white,
    justifyContent: 'center',
    width: '100%',
  },
  'color',
  'position',
  'top',
  'width',
  'justifyContent',
  'display',
)

const Container = system(
  {
    extend: Flex,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    my: '20px',
    maxWidth: '1400px',
    flexWrap: 'wrap',
    px: ['24px', '45px'],
  },
  'space',
  'width',
  'maxWidth',
)

const BottomBorder = system(
  {
    extend: Box,
    width: '100%',
    height: '4px',
    bg: colors.lightTeal,
    zIndex: -1,
  },
  'width',
  'height',
  'color',
  'zIndex',
)

export default class Header extends Component {
  render() {
    return (
      <Box>
        <Wrapper>
          <Container>
            <Logo />
            <Navigation />
            <MobileNavigation />
          </Container>
        </Wrapper>
        <BottomBorder />
      </Box>
    )
  }
}
