import styled from 'styled-components'
import colors from '../../utils/colors'
import { fonts } from 'theme'

const Wrapper = styled.footer`
  color: ${colors.pureWhite};
  background-color: ${colors.darkTeal};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
`

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
`

const MenuContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
  min-width: 200px;
`

const Title = styled.h4`
  color: ${colors.pureWhite};
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  font-family: ${fonts.main};
  letter-spacing: 0.075em;
  a {
    display: block;
    line-height: inherit;
    text-decoration: none;
    color: ${colors.pureWhite};
    font-weight: 600;
  }
`

const Menu = styled.ul`
  list-style: none;
  margin: 0;
`

const MenuItem = styled.li`
  color: ${colors.pureWhite};
  font-size: 15px;
  font-family: ${fonts.main};
  letter-spacing: 0.075em;
  padding-bottom: 4px;
  a {
    text-decoration: none;
    color: ${colors.pureWhite};
    font-weight: 400;
  }
`

const Copyright = styled.p`
  margin: 0;
  padding-top: 20px;
  color: ${colors.pureWhite};
`

export { Wrapper, Container, MenuContainer, Title, Menu, MenuItem, Copyright }