import React from 'react'
import system from '@rebass/components'
import { Box, Text } from 'rebass'
import { colors, fonts } from 'theme'
import { Link } from 'components'
import { Container, Wrapper, MenuContainer, Title, Menu, MenuItem } from './style'

const ContactTitle = system(
  {
    extend: Text,
    fontSize: '12px',
    lineHeight: '2',
    fontWeight: '500',
    fontFamily: fonts.main,
    color: colors.white20,
  },
  {
    letterSpacing: '.2em',
    textTransform: 'uppercase',
  },
)

const Contact = system(
  {
    extend: Text,
    fontFamily: fonts.main,
    fontSize: '15px',
    fontWeight: '400',
    pr: '15px',
  },
  'space',
  'color',
  {
    letterSpacing: '0.075em',
    lineHeight: '1.6',
    a: {
      textDecoration: 'none',
      color: colors.white,
      fontWeight: '400',
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

const Address = system(
  {
    extend: Box,
  },
  {
    a: {
      color: colors.white,
    },
    'a:hover': {
      color: colors.lightTeal,
    },
  },
)

export const MobileMenu = () => {
  return (
    <Wrapper>
      <Container>
        <MenuContainer>
          <Title>
            <Link to="/services">SERVICES</Link>
          </Title>
          <Menu>
            <MenuItem>
              <Link to="/services/construction">Construction</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/fabrication">Fabrication</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/turnarounds">Turnarounds</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/maintenance">Maintenance</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/services/repairs">Repairs</Link>
            </MenuItem>
          </Menu>
        </MenuContainer>
        <MenuContainer>
          <Title>
            <Link to="/about">ABOUT US</Link>
          </Title>
          <Menu>
            <MenuItem>
              <Link to="/about/leadership">Leadership</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about/our-history">Our History</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about/culture-and-values">Culture & Values</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about/community-involvement">Community Involvement</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about/geographic-reach">Geographic Reach</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/about/industries-served">Industries Served</Link>
            </MenuItem>
          </Menu>
        </MenuContainer>
        <MenuContainer>
          <Title>
            <Link to="/careers">CAREERS</Link>
          </Title>
        </MenuContainer>
        <MenuContainer>
          <Title>
            <Link to="/news">NEWS</Link>
          </Title>
        </MenuContainer>
        <MenuContainer>
          <Menu>
            <MenuItem>
              <ContactTitle>Phone Number</ContactTitle>
              <Contact>
                <Link to="tel:8444228262">(844) 4 - CATAMARAN</Link>
              </Contact>
            </MenuItem>
            <MenuItem>
              <ContactTitle>Email</ContactTitle>
              <Contact>
                <Link to="mailto:info@catamaransolutions.com">info@catamaransolutions.com</Link>
              </Contact>
            </MenuItem>
            <MenuItem>
              <ContactTitle>Address</ContactTitle>
              <Address>
                <Link to="http://www.google.com/maps/place/142%20Old%20Shreveport%20Rd.Minden,%20LA%2071055">
                  <Contact>142 Old Shreveport Rd.</Contact>
                  <Contact>Minden, LA 71055</Contact>
                </Link>
              </Address>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </Container>
    </Wrapper>
  )
}
