export default {
  backgroundWhite: '#ffffff',
  pureWhite: '#fff',
  lightTeal: '#45afab',
  darkTeal: '#226279',
  lightBlack: '#2d2e32',
  lighterBlack: '#302c2d',
  gray: '#aaa',
  lightGray: '#adadad',
  pureBlack: '#000',
}