import React from 'react'
import system from '@rebass/components'
import { Button as BaseButton, Text } from 'rebass'
import { colors, fonts } from 'theme'

const StyledButton = system(
  {
    extend: BaseButton,
    alignItems: 'center',
    bg: colors.darkTeal,
    borderBottom: ['3px solid #fff !important', '6px solid #fff !important'],
    justifyContent: 'center',
    p: '0px',
  },
  'borderBottom',
  'borderRadius',
  'height',
  'space',
  'width',
  {
    '&:hover': {
      backgroundColor: colors.lightTeal,
    },
    borderRadius: '0px !important',
    cursor: 'pointer',
    '&:disabled': {
      opacity: '0.6',
      cursor: 'not-allowed',
    },
  },
)

const CTAText = system(
  {
    extend: Text,
    color: colors.background,
    fontFamily: fonts.main,
    fontSize: ['12px', '14px'],
    fontWeight: ['700', '900'],
    letterSpacing: '0.2em',
    p: ['10px', '30px'],
  },
  'space',
  () => ({
    textTransform: 'uppercase',
  }),
)

export const Button = ({ children, ...other }) => (
  <StyledButton {...other}>
    <CTAText>{children}</CTAText>
  </StyledButton>
)
