import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { theme } from 'theme'
import { Header, Footer, Content, Container, Wrapper, Page } from './style'

const Layout = props => {
  const { children, page } = props

  return (
    <ThemeProvider theme={theme}>
      <Page>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Catamaran Solutions</title>
          <meta property="og:title" content="Catamaran Solutions" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://www.catamaransolutions.com/" />
          <meta property="og:image" content="https://www.catamaransolutions.com/oglogo.png" />
          <meta
            property="og:image:secure_url"
            content="https://www.catamaransolutions.com/oglogo.png"
          />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image:alt" content="Catamaran Logo" />
        </Helmet>
        <Header />
        <Container>
          <Wrapper>
            <Content page={page}>{children}</Content>
            <Footer />
          </Wrapper>
        </Container>
      </Page>
    </ThemeProvider>
  )
}

export { Layout }
